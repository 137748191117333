@import "../styles/breakpoints";

* {
  line-height: 1.5;
}

h1,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

h1,
h2,
h3 {
  font-weight: 500;
  font-size: 28px;

  @include breakpoint(min-large) {
    font-size: 36px;
  }
}

p {
  font-size: 16px;
  font-weight: 300;

  @include breakpoint(min-large) {
    font-size: 20px;
  }
}
