@import "../../styles/breakpoints";

.wrapper {
  background-color: #2f2f2f;
  color: #fff;
  height: 436px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 26px;

  img {
    width: 50%;
    @include breakpoint(min-large) {
      width: initial;
    }
  }
}

.icons {
  display: flex;
  margin-top: 16px;
  svg {
    margin-right: 20px;
  }
}
