@import "./styles/breakpoints";
@import "./styles/typography";
@import "./styles/button";

h1,
h2,
h3,
p {
  margin-top: 0;
}

h1,
h2,
h3 {
  margin-bottom: 0;
}

.wrapper {
  max-width: 100%;
  // height: 2000px;
  padding: 0 24px;
  @include breakpoint(min-large) {
    margin: auto;
    padding: 0;
    max-width: 1024px;
  }
}

.pages {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  @include breakpoint(min-medium) {
    margin-top: 100px;
  }

  @include breakpoint(min-large) {
    margin-top: 120px;
  }
}

.page {
  margin-bottom: 80px;

  @include breakpoint(min-medium) {
    margin-bottom: 100px;
  }

  @include breakpoint(min-large) {
    margin-bottom: 200px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;

  margin-top: 32px;
  @include breakpoint(min-medium) {
    margin-top: 48px;
  }

  margin-bottom: 140px;
  // @include breakpoint(min-medium) {
  //   margin-bottom: 120px;
  // }

  hr {
    margin-bottom: 10px;
    width: 100%;
  }

  .sectionDesc {
    margin-top: 16px;

    @include breakpoint(min-medium) {
      margin-top: 0;
    }

    display: flex;
    flex-direction: column;
    order: 1;
    flex: 1;
  }

  button {
    margin-top: 10px;
    align-self: center;
  }

  @include breakpoint(min-medium) {
    flex-direction: row;
    align-items: flex-start;

    .sectionDesc {
      order: 0;
    }
    margin-bottom: 80px;
  }
}

.lastSection {
  margin-bottom: 0;
}

.aboutImages {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  grid-gap: 10px;
  padding: 0 24px;
  margin-top: 20px;
  margin-bottom: 40px;
  @include breakpoint(min-medium) {
    margin-top: 80px;
    grid-gap: 60px;
  }
}

.dividerDiv {
  width: 0;
  @include breakpoint(min-small) {
    width: 60px;
  }
}

.vl {
  border-left: 2px solid #000;
  height: 84px;
  margin: auto;
}

.subTitle {
  color: #747474;
  font-size: 18px;
  font-weight: 400;

  @include breakpoint(min-large) {
    font-size: 22px;
  }
}
