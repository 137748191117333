@import "../../styles/breakpoints";

.wrapper {
  width: 75%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoint(min-large) {
    width: 50%;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
}

.round {
  width: 50%;
  img {
    border-radius: 50%;
    width: 100%;
    @include breakpoint(min-medium) {
      width: 65%;
    }
    @include breakpoint(min-large) {
      width: 65%;
    }
  }
}
