@import "../styles/typography";

.button {
  cursor: pointer;
  border: none;
  font-family: "Roboto Slab", serif;
  background-color: #545454;
  color: #fff;
  font-weight: 300;
  width: max-content;

  border-radius: 4px;
  display: flex;
  align-items: center;
  //   height: 78px;

  &:hover {
    background-color: #aaa;
    color: black;
  }

  font-size: 16px;
  padding: 14px 18px;

  @include breakpoint(min-medium) {
    font-size: 20px;
    padding: 16px 32px;
  }

  text-decoration: none;
}
