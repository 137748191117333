@mixin breakpoint($point) {
  @if $point == min-extraextralarge {
    @media screen and (min-width: 1680px) {
      @content;
    }
  } @else if $point == min-extralarge {
    @media screen and (min-width: 1443px) {
      @content;
    }
  } @else if $point == min-large {
    @media screen and (min-width: 1023px) {
      @content;
    }
  } @else if $point == min-medium {
    @media screen and (min-width: 767px) {
      @content;
    }
  } @else if $point == min-small {
    @media screen and (min-width: 639px) {
      @content;
    }
  }
}
