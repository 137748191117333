@import "../../styles/breakpoints";

.wrapper {
  //   width: 100%;
  // display: flex;
  // flex-direction: column;

  // @include breakpoint(min-small) {
  //   flex-direction: row;
  //   align-items: flex-end;
  // }
}

hr {
  height: 1px;
  color: #000;
  background-color: #000;
  border: none;
}

.desktopWrapper {
  display: none;

  @include breakpoint(min-small) {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  hr {
    position: absolute;
    top: 114px;
    //   left: 0;
    //   right: 208px;
    // width: 1024px;
    width: calc(100vw - 48px);
    z-index: -1;

    @include breakpoint(min-large) {
      width: 1024px;
    }
  }

  .logo {
    background-color: white;
    z-index: 999;
    border-radius: 2px;
    width: fit-content;
    height: fit-content;
    border: 1px solid #000;

    margin: 41px auto 0;
    padding: 10px;

    svg {
      width: 42px;
      height: 91px;
    }
  }

  .navItem {
    font-size: 16px;
    margin: 0;
    display: none;
    color: black;

    @include breakpoint(min-small) {
      display: block;
      cursor: pointer;
      font-weight: 400;
      margin: 0 16px;

      &:first-of-type {
        margin-left: 8px;
      }

      &:last-of-type {
        margin-right: 8px;
      }

      text-decoration: none;
    }
  }
}

.navItem:hover,
.navItemMob:hover {
  text-decoration: underline;
}

.mobWrapper {
  display: flex;
  flex-direction: row;

  .logoMob {
    border: none;
    background-color: white;
    z-index: 999;
    border-radius: 2px;
    width: fit-content;
    height: fit-content;
    padding: 4px;
    border: 1px solid #000;
    margin-top: 16px;

    // svg {
    //   width: 29px;
    //   height: 64px;
    // }
  }

  .mobContent {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .navItemsMob {
    display: flex;

    // justify-content: space-around;
    justify-content: space-around;
    // margin-top: 12px;

    @include breakpoint(min-small) {
      display: none;
    }
  }

  @include breakpoint(min-small) {
    display: none;
  }

  hr {
    width: 100%;
    // flex: 1;
    margin-top: 44px;
  }
}

.navItemMob {
  cursor: pointer;
  font-weight: 400;
  // margin-right: 16px;
  font-size: 14px;
  text-decoration: none;
  padding: 0 4px;
  color: black;
}
